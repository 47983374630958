import * as Config from "configuration";
import * as Auth from "oauth2";
const origin = Config.get("apiNotification");
const TIMEOUT = 10000;
export function fetchNotifications(noEcole) {
    let url = `${origin}/api/notification/list`;
    if (noEcole) {
        url += `?noEcole=${noEcole}`;
    }
    return Auth.fetch(url);
}
export function fetchNotification(id) {
    return Auth.fetch(`${origin}/api/notification/${id}`);
}
export async function fetchExpoSubscribe(token) {
    const url = `${origin}/api/notification/subscribe`;
    return Auth.fetch(url, {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ token }),
    }, TIMEOUT);
}
export function fetchExpoUnsubscribe(token) {
    const url = `${origin}/api/notification/unsubscribe`;
    return Auth.fetch(url, {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ token }),
    }, TIMEOUT);
}
export async function fetchSubscriptions() {
    const url = `${origin}/api/notification/subscriptions`;
    return Auth.fetch(url, { method: "POST" }, TIMEOUT);
}
export async function fetchUsers(filters) {
    const url = `${origin}/api/notification/users`;
    return Auth.fetch(url, {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ filters }),
    });
}
export async function fetchResolveRecipients(props) {
    const url = `${origin}/api/notification/resolve-recipients`;
    const bodyObject = {
        ...props,
        filters: {
            ...props.filters,
        },
    };
    if (bodyObject.module === "esf-academy") {
        delete bodyObject.filters?.scale;
        bodyObject.filters = {
            ...bodyObject.filters,
            roles: ["MONITEUR"],
        };
    }
    return Auth.fetch(url, {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(bodyObject),
    });
}
export async function broadcast(body) {
    const url = `${origin}/api/notification/broadcast`;
    return Auth.fetch(url, {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(body),
    });
}
